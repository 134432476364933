<template>
  <v-card id="configuration-setting-card">
      <h1>Configuration</h1>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'


export default {
  components: {
  },
  setup() {
    const tab = ref('')

    return {
    }
  },
}
</script>
